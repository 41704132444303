<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('STOCK_ENTRY.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyStockEntryIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group id="select-group-2" :label="`${$t('STOCK_ENTRY.PRODUCT')}:`" label-cols-md="2" label-align-md="right" label-for="select-2">
              <SelectInfinityScroll
                ref="selectClient"
                id="select-2"
                v-model="$v.form.product.$model"
                :search.sync="search"
                api="stock/products"
                :state="validateState('product') === false"
                @input="clearServerError('product')"
                clearable
              />
            </b-form-group>
            <b-form-group :label="`${$t('STOCK_ENTRY.QUANTITY')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.quantity.$model"
                :state="validateState('quantity')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                type="number"
                trim
                @input="clearServerError('quantity')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.quantity">{{ serverErrors.quantity[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('STOCK_ENTRY.QUANTITY') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="`${$t('STOCK_ENTRY.RATE')}`" label-for="input-2" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-2"
                v-model="$v.form.cost.$model"
                :state="validateState('cost')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                type="number"
                trim
                @input="clearServerError('cost')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.cost">{{ serverErrors.cost[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('STOCK_ENTRY.RATE') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="select-group-1"
              :label="`${$t('STOCK_ENTRY.WAREHOUSE')}:`"
              label-cols-md="2"
              label-align-md="right"
              label-for="select-1"
            >
              <SelectInfinityScroll
                ref="selectClient"
                id="select-1"
                v-model="$v.form.warehouse.$model"
                :search.sync="search"
                api="stock/warehouses"
                :state="validateState('warehouse') === false"
                @input="clearServerError('warehouse')"
                clearable
              />
            </b-form-group>
            <b-form-group
              :label="`${$t('STOCK_ENTRY.PRODUCT_FROM')}`"
              v-slot="{ ariaDescribedby }"
              class="align-items-center"
              label-for="radio-1"
              label-cols-md="2"
              label-align-md="right"
            >
              <b-form-radio-group
                id="radio-group-2"
                v-model="source"
                :aria-describedby="ariaDescribedby"
              >
                <b-form-radio
                  value="SUPPLIER"
                >
                  Supplier
                </b-form-radio>
                <b-form-radio
                  value="WAREHOUSE"
                >
                  Warehouse
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              v-if="source === 'SUPPLIER'"
              id="select-group-3"
              key="selectSource"
              :label="`${$t('STOCK_ENTRY.SOURCE_SUPPLIER')}:`"
              label-cols-md="2"
              label-align-md="right"
              label-for="select-3"
            >
              <SelectInfinityScroll
                ref="selectSource"
                id="select-3"
                v-model="$v.form.sourceSupplier.$model"
                :search.sync="search"
                api="stock/product-suppliers"
                :state="validateState('sourceSupplier') === false"
                @input="onChangeSupplier"
                clearable
              />
            </b-form-group>
            <b-form-group
              v-if="source === 'WAREHOUSE'"
              id="select-group-4"
              key="selectWarehouse"
              :label="`${$t('STOCK_ENTRY.SOURCE_WAREHOUSE')}:`"
              label-cols-md="2"
              label-align-md="right"
              label-for="select-4"
            >
              <SelectInfinityScroll
                ref="selectWarehouse"
                id="select-4"
                v-model="$v.form.sourceWarehouse.$model"
                :search.sync="search"
                api="stock/warehouses"
                :state="validateState('sourceWarehouse') === false"
                @input="onChangeWarehouse"
                clearable
              />
            </b-form-group>
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { numeric, required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/stockEntryRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../../content/Card'
import InputForm from '../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../mixins/serverVuelidate'
import SelectInfinityScroll from '../../../../../components/forms-items/SelectInfinityScroll'

export default {
  name: 'CompanyBranchesEdit',
  components: {
    InputForm,
    KTCard,
    SelectInfinityScroll,
  },
  mixins: [
    validationMixin,
    serverVuelidate,
  ],
  data() {
    return {
      loading: false,
      source: null,
      form: {
        quantity: 0,
        product: '',
        warehouse: '',
        sourceWarehouse: '',
        sourceSupplier: '',
        cost: 0,
      },
      validations: {
        form: {
          quantity: {
            required,
            numeric,
          },
          product: { required },
          warehouse: { required },
          sourceWarehouse: {},
          sourceSupplier: {},
          cost: { numeric },
        },
      },
      search: '',
    }
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('STOCK_ENTRY.TITLE_EDIT') }])
  },
  methods: {
    fetchData() {
      Repo
        .get(this.$route.params.id)
        .then(({ data }) => {
          this.setItem(data.payload)
          this.source = (this.form.sourceWarehouse && 'WAREHOUSE') || (this.form.sourceSupplier && 'SUPPLIER') || 'SUPPLIER'
        })
    },
    onChangeWarehouse() {
      this.clearServerError('sourceWarehouse')
      this.form.sourceSupplier = null
    },
    onChangeSupplier() {
      this.clearServerError('sourceSupplier')
      this.form.sourceWarehouse = null
    },
    normalizeTime(time) {
      if (!time) return ''
      return time.split(' ')[1].slice(0, 5)
    },
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.clearServerErrors()
      this.loading = true

      const obj = { ...this.form }
      obj.warehouse = obj.warehouse ? obj.warehouse.uuid : ''
      obj.product = obj.product ? obj.product.uuid : ''
      obj.sourceSupplier = obj.sourceSupplier ? obj.sourceSupplier.uuid : ''
      obj.sourceWarehouse = obj.sourceWarehouse ? obj.sourceWarehouse.uuid : ''
      Repo
        .patch(this.$route.params.id, obj)
        .then(() => {
          this.$bvToast.toast(this.$t('TOAST.EDITED'), {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true,
          })
          setTimeout(() => this.$router.push({ name: 'companyStockEntryIndex' }), TIMEOUT_REDIRECT)
        })
        .catch((err) => {
          this.seServerErrors(err.response.data)
        })
        .finally(() => this.loading = false)
    },
  },
}
</script>
